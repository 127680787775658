import { BigNumber } from '@ethersproject/bignumber'
import { Pool } from '@pancakeswap/uikit'
import { SerializedWrappedToken } from '@pancakeswap/token-lists'
import { ChainDefaultId, ChainId, mapChainConfig } from '@pancakeswap/sdk'
import Trans from 'components/Trans'
import { IsCakeVault, IsLocked, Locked2Flexible, VaultKey, VaultList } from 'state/types'
import { tokens } from '@pancakeswap/tokens'
import { PoolCategory } from './types'

export const MAX_LOCK_DURATION = 31536000
export const UNLOCK_FREE_DURATION = 1209600
export const ONE_WEEK_DEFAULT = 604800
export const BOOST_WEIGHT = BigNumber.from('20000000000000') // TODO! make equal with CakePool contract
export const DURATION_FACTOR = BigNumber.from('31536000')


export const VAULT_EMISSIONS = {
  [ChainId.ETHEREUM]: {
    [VaultKey.NineInchVault]: 14,
    [VaultKey.CakeVault]: 52,
    [VaultKey.PpVault]: 5,
    [VaultKey.PldVault]: 5,
    [VaultKey.WhethVault]: 2,
    [VaultKey.DbiVault]: 5,
    [VaultKey.HexVault]: 1,
    [VaultKey.ShibVault]: 1,
    [VaultKey.BenVault]: 1,
    [VaultKey.PepeVault]: 1,
  },
  [ChainId.PULSECHAIN]: {
    [VaultKey.NineInchVault]: 15, // 15
    [VaultKey.CakeVault]: 53, // 53
    [VaultKey.PpVault]: 5,
    [VaultKey.PldVault]: 3,
    [VaultKey.WhethVault]: 2,
    [VaultKey.PlsxVault]: 9,
    [VaultKey.HexVault]: 1,
    [VaultKey.SolidxVault]: 1,
  },
}

export const POOL_EMISSIONS = {
  [ChainId.ETHEREUM]: {
    '9INCH': 0.001,
    'BBC': 0.001,
  },
  [ChainId.PULSECHAIN]: {
    '9INCH': 0.001,
    'BBC': 0.001,
  },
  [ChainId.SEPOLIA]: {
    '9INCH': 1,
    'BBC': 1,
  },
}

const availTokens: any = Object.values(tokens).reduce((tokenAll: any, tokenList: any) => {
  return {
    ...tokenAll, ...tokenList
  }
}, {})

const vaultPools = [
  ...VaultList.map((key, i) => {
    const token = String(key).substring(0, String(key).length - 5)
    const name = Object.keys(VaultKey)[Object.values(VaultKey).indexOf(key)]
    const stakingToken = availTokens[token]
    if (!stakingToken) return undefined
    return {
      sousId: i * 2,
      vaultKey: key,
      stakingToken,
      earningToken: tokens[ChainDefaultId].cake,
      contractAddress: mapChainConfig(name),
      poolCategory: PoolCategory.CORE,
      tokenPerBlock: '10',
      isFinished: false,
    }
  }).filter(pool => !!pool),
  ...VaultList.map((key, i) => {
    const token = String(key).substring(0, String(key).length - 5)
    const keyFlexible = Locked2Flexible(key)
    const name = Object.keys(VaultKey)[Object.values(VaultKey).indexOf(keyFlexible)]
    const stakingToken = availTokens[token]
    if (!stakingToken) return undefined
    return {
      sousId: i * 2 + 1,
      vaultKey: keyFlexible,
      stakingToken,
      earningToken: tokens[ChainDefaultId].cake,
      contractAddress: mapChainConfig(name),
      poolCategory: PoolCategory.CORE,
      tokenPerBlock: '10',
      isFinished: false,
    }
  }).filter(pool => !!pool)
]

// const smartPools = Object.values(availTokens).map((token: any, index) => ({
const smartPools = Object.values(tokens[ChainDefaultId]).map((token: any, index) => ({
  sousId: 1000 + index,
  stakingToken: tokens[ChainDefaultId].cake,
  earningToken: token,
  contractAddress: mapChainConfig(`SmartChef${token.symbol}`),
  poolCategory: PoolCategory.CORE,
  tokenPerBlock: '0.000001',
  version: 3,
})).filter(pool => Object.keys(pool.contractAddress).length)

// console.log('mapChainConfig(`SmartChef${token.symbol}`),', mapChainConfig(`SmartChef6INCH`))
// console.log('smartPools', smartPools)

export const livePools: Pool.SerializedPoolConfig<SerializedWrappedToken>[] = [
  ...vaultPools,
  ...smartPools,
].map((p) => ({
  ...p,
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}))

export const vaultPoolConfig = {
  ...(
    vaultPools.reduce((prev, pool) => {
      const isLocked = IsLocked(pool.vaultKey)
      const isCakeVault = IsCakeVault(pool.vaultKey)
      return {
        ...prev,
        [pool.vaultKey]: {
          name: isLocked ? `Stake ${pool.stakingToken.symbol}` : `Flexible ${pool.stakingToken.symbol}`,
          description: isCakeVault ? <Trans>Stake, Earn – And more!</Trans> : <Trans symbol={pool.stakingToken.symbol}>Stake %symbol%, Earn BBC</Trans>,
          autoCompoundFrequency: 5000,
          gasLimit: 600000,
          tokenImage: {
            primarySrc: `/images/symbols/${pool.stakingToken.symbol.toLowerCase()}.png`,
            secondarySrc: isCakeVault ? `/images/tokens/${isLocked ? 'locked.png' : 'autorenew.svg'}` : `/images/symbols/bbc.png`,
          },
        },
      }
    }, {})
  )
} as const


// known finished pools
const finishedPools = [].map((p) => ({
  ...p,
  isFinished: true,
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}))



export default [...livePools, ...finishedPools] as Pool.SerializedPoolConfig<SerializedWrappedToken>[]
